<style lang="scss" scoped>
@import "@assets/styles/variables.scss"; //公共样式变量
.el-tree {
  margin-top: 15px;
}
</style>

<template>
  <!-- 角色管理 -->
  <section class="page-container">
    <div class="table-box">
      <!-- 搜索表单 -->
      <el-form :inline="true" :model="searchForm" class="search-form">
        <el-form-item label="角色名称：">
          <el-input v-model="searchForm.name" placeholder="角色名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch" icon="el-icon-search">
            查询
          </el-button>
        </el-form-item>
      </el-form>
      <!-- 数据内容 -->
      <div class="table-container" id="tableContainer">
        <!-- 内页工具栏 -->
        <div class="tool-bar">
          <div class="tool-left">
            <el-button
              size="small"
              type="primary"
              icon="el-icon-plus"
              @click="formDialogVisible = true"
            >
              添加
            </el-button>
            <!-- <el-button
              size="small"
              type="danger"
              icon="el-icon-delete"
              @click="handleDelete()"
            >
              删除
            </el-button> -->
          </div>
          <div class="tool-right">
            <el-tooltip effect="dark" content="刷新">
              <el-button
                size="small"
                icon="el-icon-refresh-right"
                @click="handleRefresh"
              />
            </el-tooltip>
            <el-tooltip effect="dark" content="全屏">
              <el-button size="small" @click="handleFullScreen">
                <span class="iconfont">
                  {{ (isFull && "&#xe641;") || "&#xe8fa;" }}
                </span>
              </el-button>
            </el-tooltip>
          </div>
        </div>
        <div class="table-data" ref="tableContainer">
          <!-- 内页表格数据 -->

          <!-- 多选加上 属性 ：@selection-change="handleSelectionChange" -->
          <el-table
            ref="tableBox"
            border
            style="width: 100%"
            :max-height="tableHeight"
            :data="tableData"
          >
            <!-- <el-table-column
              type="selection"
              width="55"
              align="center"
              fixed="left"
            /> -->
            <el-table-column prop="id" label="ID" width="80" align="center" />
            <el-table-column
              prop="name"
              label="角色名称"
              min-width="180"
              align="center"
            />
            <el-table-column label="状态" width="180" align="center">
              <template slot-scope="scope">
                <el-switch
                  :active-value="1"
                  :inactive-value="2"
                  v-model="scope.row.status"
                  @change="statusChange(scope.row.id, scope.row.status)"
                />
              </template>
            </el-table-column>
            <el-table-column
              prop="sort"
              label="排序"
              width="80"
              align="center"
            />
            <el-table-column label="创建时间" width="210" align="center">
              <template slot-scope="scope">
                <p>
                  {{
                    (scope.row.create_time * 1000)
                      | formatTime("YYYY-MM-DD HH:mm:ss")
                  }}
                </p>
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              width="320"
              align="center"
              fixed="right"
            >
              <template slot-scope="scope">
                <el-button
                  class="color-primary"
                  type="text"
                  icon="el-icon-edit"
                  @click="getDetail(scope.row.id, 'role_group')"
                >
                  编辑
                </el-button>
                <el-button
                  class="color-primary"
                  type="text"
                  icon="el-icon-s-operation"
                  @click="getDetail(scope.row.id, 'member')"
                >
                  人员分配
                </el-button>
                <el-button
                  class="color-primary"
                  type="text"
                  icon="el-icon-coordinate"
                  @click="getDetail(scope.row.id, 'role')"
                >
                  权限分配
                </el-button>
                <el-button
                  class="color-danger"
                  type="text"
                  icon="el-icon-delete"
                  @click="handleDelete(scope.row.id)"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 分页控件 -->
        <el-pagination
          background
          layout="total,sizes,prev, pager, next,jumper"
          :current-page="pagination.current"
          :page-size="pagination.size"
          :page-sizes="pagination.sizes"
          :total="pagination.total"
          @size-change="handleSize"
          @current-change="handleCurrent"
        >
        </el-pagination>
      </div>
      <!-- 新增/编辑表单弹窗 -->
      <el-dialog
        :title="(id && '修改角色') || '添加角色'"
        :visible.sync="formDialogVisible"
        width="460px"
        @close="handleCancle('role_group')"
      >
        <el-form
          :model="formData"
          :rules="rules"
          ref="formData"
          label-width="84px"
        >
          <el-form-item label="角色名称:" prop="name">
            <el-input v-model="formData.name" placeholder="请输入角色名称" />
          </el-form-item>
          <el-form-item label="角色状态:" prop="status">
            <el-radio-group v-model="formData.status">
              <el-radio :label="1">启用</el-radio>
              <el-radio :label="2">禁用</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="排序:" prop="sort">
            <el-input-number v-model="formData.sort" :min="1" />
          </el-form-item>
          <el-form-item label="备注:">
            <el-input
              v-model="formData.remarks"
              placeholder="请输入角色备注"
              type="textarea"
              :rows="5"
              maxlength="200"
              show-word-limit
            >
            </el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="handleCancle('role_group')">取 消</el-button>
          <el-button type="primary" @click="handleSubmit('role_group')">
            确 定
          </el-button>
        </div>
      </el-dialog>
      <!-- 人员分配弹窗 -->
      <el-dialog
        title="菜单配置"
        :visible.sync="menuDialogVisible"
        width="440px"
        @close="handleCancle('member')"
      >
        <!-- 列表数据过多可添加筛选功能 -->
        <el-input placeholder="输入关键字进行过滤" v-model="memberFilter">
        </el-input>
        <!-- el-tree筛选添加属性 => :filter-node-method="memberFilterNode" -->
        <el-tree
          :data="memberTreeData"
          :props="memberProps"
          node-key="id"
          show-checkbox
          default-expand-all
          :filter-node-method="memberFilterNode"
          :check-on-click-node="true"
          ref="memberTree"
        >
        </el-tree>
        <div slot="footer" class="dialog-footer">
          <el-button @click="handleCancle('member')">取 消</el-button>
          <el-button type="primary" @click="handleSubmit('member')">
            保 存
          </el-button>
        </div>
      </el-dialog>
      <!-- 操作权限分配弹窗 -->
      <el-dialog
        title="分配权限"
        :visible.sync="buttonDialogVisible"
        width="440px"
        @close="handleCancle('role')"
      >
        <el-input placeholder="输入关键字进行过滤" v-model="roleFilter">
        </el-input>

        <el-tree
          class="filter-tree"
          :data="roleData"
          show-checkbox
          node-key="id"
          :default-expanded-keys="roleDefaultExpandedKeys"
          :default-checked-keys="roleDefaultCheckedKeys"
          :props="roleDefaultProps"
          :filter-node-method="filterNode"
          :check-on-click-node="true"
          ref="tree"
        >
        </el-tree>
        <div slot="footer" class="dialog-footer">
          <el-button @click="handleCancle('role')">取 消</el-button>
          <el-button type="primary" @click="handleSubmit('role')">
            保 存
          </el-button>
        </div>
      </el-dialog>
    </div>
  </section>
</template>

<script>
import {
  roleGroupList,
  roleGroupUpdate,
  roleGroupStatusChange,
  roleGroupAdd,
  roleGroupDelete,
  roleList,
} from "@api/role";
import { getUserListAll } from "@api/user";
import { PAGE_SIZE, PAGE_SIZES } from "@config";
export default {
  name: "role",
  inject: ["reload"],
  data() {
    return {
      tableHeight: 0, //表格最大高度
      searchForm: {}, //搜索表单
      isFull: false, //全屏开启
      tableData: [],
      multipleSelection: [], //表格多选储存数据
      pagination: {
        page: 1,
        total: 100,
        current: 1,
        size: PAGE_SIZE,
        sizes: PAGE_SIZES,
      }, //分页数据
      id: null, //修改角色id
      formDialogVisible: false, //修改/添加表单弹窗显示
      formData: {
        sort: 200,
        status: 1,
      }, //表单存储数据
      rules: {
        name: [{ required: true, message: "请输入角色名称", trigger: "blur" }],
        sort: [
          { required: true, message: "请选择角色排序", trigger: "change" },
        ],
        status: [
          { required: true, message: "请选择角色状态", trigger: "change" },
        ],
      }, //表单校验规则
      menuDialogVisible: false, //人员配置弹窗显示
      memberFilter: "", //人员列表筛选关键字
      memberIds: [], //勾选的人员id
      // memberNodes: [], //勾选的人员对象
      memberTreeData: [], //树状数据列表
      memberProps: {
        label: "name", //指定节点标签的属性值
        children: "_child", //指定子树的属性值
      }, //树状数据配置项
      buttonDialogVisible: false, //操作权限分配弹窗显示
      roleFilter: "", //权限 搜索内容
      roleData: [], //权限数据源
      roleDefaultCheckedKeys: [], //权限默认选中节点
      roleDefaultExpandedKeys: [], //权限默认展开节点
      roleDefaultProps: {
        //权限树形 默认配置
        children: "_child", //指定节点标签的属性值
        label: "name", //指定子树的属性值
      },
    };
  },
  watch: {
    /** 监听文字改变，实现筛选 */
    memberFilter(val) {
      //人员筛选
      this.$refs["memberTree"].filter(val);
    },
    roleFilter(val) {
      //权限筛选
      this.$refs["tree"].filter(val);
    },
  },
  created() {
    // 获取初始化数据
    let tempParams = {
      page: this.pagination.page,
      rows: this.pagination.size,
    };
    this.getData(tempParams);
    this.getMemberTreeData();
    this.getRoleListData();
    /**
     * 页面dom元素加载完后调用，计算表格最大高度
     */
    this.$nextTick(function () {
      this.initTableHeight();
    });
  },
  mounted() {
    window.addEventListener("resize", () => {
      // 窗口尺寸改变时触发事件
      if (!this.$screenfull.isFullscreen) {
        this.isFull = false;
      }
      this.initTableHeight();
    });
  },
  methods: {
    /** 获取表格数据 */
    async getData(tempParams) {
      /**
       * 请求接口数据
       */
      let res = await roleGroupList(tempParams);
      this.tableData = res.data.list;
      this.pagination.total = res.data.count;
      this.$forceUpdate();
    },
    /** 权限根据关键字筛选 */
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    /** 人员数据根据关键字筛选 */
    memberFilterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    /** 权限组状态变更 */
    async statusChange(id, status) {
      let res = await roleGroupStatusChange(
        {
          id: id,
          name: this.formData.name,
          status: status,
        },
        "post"
      );
      if (res.code == 1) {
        this.$message({
          type: "success",
          message: res.msg,
        });
      }
    },
    /** 分页参数-每页显示条数改变 */
    handleSize(val) {
      this.pagination = {
        ...this.pagination,
        size: val,
      };
       let tempParams = {
        ...this.searchForm,
        page: this.pagination.page,
        rows: this.pagination.size,
      };
      this.getData(tempParams);
    },
    /** 分页参数-当前页改变 */
    handleCurrent(val) {
      this.pagination = {
        ...this.pagination,
        page: val,
      };
      let tempParams = {
        ...this.searchForm,
        page: this.pagination.page,
        rows: this.pagination.size,
      };
      this.getData(tempParams);
    },
    /** 查询 */
    handleSearch() {
      this.pagination = {
        ...this.pagination,
        page: 1,
      };
      let tempParams = {
        ...this.searchForm,
        page: this.pagination.page,
        rows: this.pagination.size,
      };
      this.getData(tempParams);
    },
    /** 刷新页面 */
    handleRefresh() {
      this.reload();
    },
    /** 初始化表格高度 */
    initTableHeight() {
      if (this.$refs["tableContainer"]) {
        this.tableHeight = this.$refs["tableContainer"].offsetHeight;
      }
    },
    /** 全屏 */
    handleFullScreen() {
      const element = document.getElementById("tableContainer");
      this.isFull = !this.isFull;
      if (this.isFull) {
        this.$screenfull.request(element);
      } else {
        this.$screenfull.exit(element);
      }
    },
    /** 表格多选触发 */
    // handleSelectionChange(val) {
    //   this.multipleSelection = val;
    // },
    /** 获取人员树型列表数据 */
    async getMemberTreeData() {
      // 模拟数据
      let data = await getUserListAll();
      this.memberTreeData = data.data;
    },
    /** 获取操作权限列表数据 */
    async getRoleListData() {
      // 模拟数据
      let data = await roleList();

      this.roleData = data.data;
      // data.data.forEach((item) => {
      //   this.roleData.push({
      //     menuId: item.menuId,
      //     checkList: [],
      //   });
      // });
    },
    /** 修改前获取详情信息 */
    async getDetail(id, type) {
      this.id = id;
      let res = await roleGroupUpdate({ id: id }, "get"); //权限组详情
      this.formData = res.data;

      switch (type) {
        case "role_group":
          /**
           * 请求接口获取详情数据(角色详情)
           */

          // 数据请求返回成功后打开弹窗
          this.formDialogVisible = true;
          break;
        case "member":
          /**
           * 请求接口获取详情数据(菜单配置详情)
           */
          /** 延时设置，避免数据赋值时..树型列表加载未完成报错 */
          setTimeout(() => {
            this.$refs["memberTree"].setCheckedKeys(
              this.formData.member_ids_arr
            ); //根据获取到的id数组渲染树型列表
            // this.$refs["memberTree"].setCheckedKeys(this.memberNodes); //根据获取到的对象数组渲染树型列表
          }, 200);

          // 数据请求返回成功后打开弹窗
          this.menuDialogVisible = true;
          break;
        case "role":
          setTimeout(() => {
            this.$refs["tree"].setCheckedKeys(this.formData.role_acts_ids_arr); //根据获取到的id数组渲染树型列表
          }, 200);
          this.buttonDialogVisible = true;
          // 数据请求返回成功后打开弹窗
          break;
        default:
          break;
      }
    },
    /** 取消表单填写 */
    handleCancle(type) {
      this.id = null;
      this.formData = {
        sort: 200,
        status: 1,
      };
      switch (type) {
        case "role_group":
          this.formDialogVisible = false;

          this.$refs["formData"].clearValidate();
          break;
        case "member":
          this.memberIds = [];
          this.memberNodes = [];
          this.$refs["memberTree"].setCheckedKeys([]); //清空菜单勾选状态
          this.menuDialogVisible = false;
          break;
        case "role":
          this.$refs["tree"].setCheckedKeys([]);
          this.buttonDialogVisible = false;
          break;
        default:
          break;
      }
    },
    /** 提交表单*/
    async handleSubmit(type) {
      let res = null;
      switch (type) {
        case "role_group":
          this.$refs["formData"].validate(async (valid) => {
            if (valid) {
              if (this.id) {
                // 存在id，执行编辑请求
                res = await roleGroupUpdate({ ...this.formData }, "post");
              } else {
                // 不存在id，执行添加请求
                res = await roleGroupAdd({
                  ...this.formData,
                });
              }

              if (res.code == 1) {
                this.$message({
                  type: "success",
                  message: res.msg,
                });
              }
              // 请求后，不论是否成功，调用方法关闭弹窗
              this.handleCancle(type);

              // 请求成功后，调用方法，更新页面数据
              this.getData();
            }
          });
          break;
        case "member":
          this.memberIds = this.$refs["memberTree"].getCheckedKeys().toString(); //获取所选人员id数组并赋值
          // this.memberNodes = this.$refs["memberTree"].getCheckedNodes(); //获取所选人员对象数组并赋值
          /**
           * 请求保存人员勾选配置
           */
          res = await roleGroupUpdate(
            {
              id: this.id, //角色id
              name: this.formData.name,
              member_ids: this.memberIds, //获取选中的节点
            },
            "post"
          );
          if (res.code == 1) {
            this.$message({
              type: "success",
              message: res.msg,
            });
          }
          // 请求后，不论是否成功，调用方法关闭弹窗
          this.handleCancle(type);
          break;

        case "role":
          /**
           * 请求保存操作权限勾选配置
           */
          res = await roleGroupUpdate(
            {
              id: this.id, //角色id
              name: this.formData.name,
              role_acts_ids: this.$refs["tree"].getCheckedKeys().toString(), //获取选中的节点
            },
            "post"
          );
          if (res.code == 1) {
            this.$message({
              type: "success",
              message: res.msg,
            });
          }

          // 请求后，不论是否成功，调用方法关闭弹窗
          this.handleCancle(type);
          break;
        default:
          break;
      }
    },
    /** 删除 */
    handleDelete(id) {
      /** 判断id是否存在，存在即单个删除。反之触发批量删除 */
      if (!id && id != 0) {
        if (this.multipleSelection.length > 0) {
          id = this.multipleSelection.map((item) => item.id);
        }
      }

      if (id || id == 0) {
        id = (id instanceof Array && id.join(",")) || id;

        /** 弹窗再次确认操作 */
        this.$confirm("此操作将永久删除该角色, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          /** 确认触发 */
          .then(async () => {
            /**
             * 请求接口删除数据
             */
            let res = await roleGroupDelete({
              id: id,
            });
            // this.$refs['tableBox'].clearSelection(); //删除操作请求失败，清空表格多选

            if (res.code == 1) {
              /** 判断当前页数据是否仅剩1条 */
              if (this.tableData.length == 1) {
                this.pagination = {
                  ...this.pagination,
                  page:
                    (this.pagination.page > 1 && this.pagination.page - 1) || 1, // 判断当前页是否为第一页，不为第一页则减少一页
                };
              }
              this.$message({
                type: "success",
                message: "角色已删除!",
              });
            }
            this.getData();
          })
          /** 取消触发 */
          .catch(() => {
            this.$refs["tableBox"].clearSelection(); //删除操作取消，清空表格多选
            this.$message({
              type: "info",
              message: "已取消删除操作",
            });
          });
      }
    },
  },
};
</script>